{
  "locale": {
    "spanish": "castellano",
    "english": "english",
    "print": "save"
  },
  "header": {
    "title": "zubiaga",
    "subtitle": "frontend engineer"
  },
  "carrer": {
    "title": "career",
    "text": [
      "Throughout my career I have worked shoulder to shoulder with high profile companies and clients. Together we've developed many tools, from web services to tailored content managers. Always sharing and learning, I'm proud of the road I've walked."
    ]
  },
  "clients": {
    "title": "worked with",
    "companiesLeft": [
      "porsche",
      "guggenheim",
      "imq",
      "saunier duval",
      "metro madrid"
    ],
    "companiesRight": [
      "kutxabank",
      "balenciaga",
      "axa",
      "inditex",
      "línea directa"
    ]
  },
  "experience": [
    {
      "company": "Appinio",
      "text": [
        "Data presentation with emphasis on UI/UX"
      ],
      "what": "frontend engineer",
      "when": "2022.06 / Today",
      "stack": "angular, flutter, scss, ds"
    },
    {
      "company": "Appico",
      "text": [
        "UI/UX for the automotive industry"
      ],
      "what": "frontend engineer",
      "when": "2021.10 / 2022.06",
      "stack": "vue, scrum, agile, scss, ds"
    },
    {
      "company": "Init Services",
      "text": [
        "Health related hybrid applications",
        "Team work, very close to the UX designers"
      ],
      "what": "frontend engineer",
      "when": "2019.07 / 2021.10",
      "stack": "angular, vue, pwa, ionic, scss"
    },
    {
      "company": "Zorraquino",
      "text": [
        "High profile clients, weekly deadlines, analytics",
        "Continuous coordination with team UI/UX designers"
      ],
      "what": "fullstack developer",
      "when": "2014.07 / 2019.07",
      "stack": "javascript, scss, html, php, mysql"
    },
    {
      "company": "Ipronet Sistemas",
      "text": [
        "Video surveillance cameras web integration"
      ],
      "what": "fullstack developer",
      "when": "2011.01 / 2014.07",
      "stack": "mysql, php, codeigniter"
    },
    {
      "company": "Recinfor",
      "text": [
        "Corporate web, in-house products"
      ],
      "what": "fullstack developer",
      "when": "2009.11 / 2011.01",
      "stack": "javascript, html, scss, mysql, php"
    },
    {
      "company": "Noray Bioinformatics",
      "text": [
        "Cell culture software for hospitals"
      ],
      "what": "fullstack developer",
      "when": "2007.03 / 2007.09",
      "stack": "javascript, html, scss, sqlserver, .net"
    }
  ],
  "intro": {
    "title": "hello, my name is Miguel Zubiaga, frontend engineer",
    "text": [
      "Bachelor in computer science, I've worked with the best design / development agencies.",
      "On June 2022 I joined Appinio. I work side-by-side with a multidisciplinary team, always trying to provide the best user experience.",
      "I'm available through the following channels:"
    ],
    "points": [
      "miguel.zubiaga&commat;gmail.com",
      "linkedin.com/in/mzubiaga",
      "mzubiaga.net"
    ]
  },
  "picture": {
    "title": "finally, me"
  },
  "projects": {
    "title": "some highlights",
    "clients": [
      {
        "title": "Porsche international site",
        "description": "Continuous integration of the Porsche international site with quarterly targets for investors. Vue, Scrum, Agile, Design System."
      },
      {
        "title": "Axa Health Keeper",
        "description": "Multi-region & multi-platform application. TDD approach allowed us fast and failsafe deployments on both the Web, Android and IOS. Thanks to Vue reusable components, response to incidents and new developments was lightning fast. All work was coordinated remotely."
      },
      {
        "title": "Kutxabank & Cajasur",
        "description": "A design system was developed to keep coherence across different elements. Once the base components were created, we deployed the service on an AWS instance, with the additional security requirements of the banking sector. Weekly updates brought new content and designs."
      },
      {
        "title": "Saunier Duval",
        "description": "Improve conversion rate was a must. Using bootstrap we sped up production and reached all the deadlines. We developed a search engine that responded to any input, with parameters like proximity, reviews and more to promote resutls meeting certain requirements."
      },
      {
        "title": "Guggenheim Bilbao & Cristobal Balenciaga Museums",
        "description": "Guggenheim museum needed a complete facelift, giving relevance to the most important sections. Balenciaga on the other hand allowed us to write a new platform from scratch with a content manager tailored to the client, which generated automated relevant sections based on certain events and manual entries."
      }
    ]
  },
  "skills": {
    "title": "my way",
    "points": [
      "clean code, tdd, pairing, remote,",
      "reactivity, components, mobile,",
      "expressive and manteinable code,",
      "scrum, agile, communication,",
      "teamwork, ui/ux details eagle-eye,",
      "decision maker, resolutive"
     ]
  },
  "stack": {
    "tech": {
      "title": "tech stack",
      "text": [
        "vue, jest, ionic, angular, pwa, php, node, mysql, es6+, javascript, html, css, sass, bem, bootstrap, aws, cordova, capacitor, git, jira"
      ]
    },
    "langs": {
      "title": "hola languages",
      "text": [
        "spanish, english"
      ]
    }
  },
  "studies": {
    "title": "young me achivements",
    "text": [
      "prifysgol cymru, university of wales",
      "bachelor of science in computing",
      "san luis center",
      "computer applications development",
      "german school",
      "german abitur & spanish selectivity"
    ]
  }
}
