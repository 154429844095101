{
  "header": {
    "title": "zubiaga",
    "subtitle": "frontend engineer"
  },
  "carrer": {
    "title": "carrera profesional",
    "text": [
      "A lo largo de mi carrera he trabajado codo con codo con empresas y clientes de perfil alto. Juntos hemos desarrollado herramientas de todo tipo, desde servicios web hasta gestores de contenido personalizados. Siempre compartiendo y aprendiendo, puedo estar orgulloso del camino recorrido."
    ]
  },
  "clients": {
    "title": "he trabajado con",
    "companiesLeft": [
      "porsche",
      "guggenheim",
      "imq",
      "saunier duval",
      "metro madrid"
    ],
    "companiesRight": [
      "kutxabank",
      "balenciaga",
      "axa",
      "inditex",
      "línea directa"
    ]
  },
  "experience": [
    {
      "company": "Appinio",
      "text": [
        "Presentación de datos con énfasis en UI/UX"
      ],
      "what": "frontend engineer",
      "when": "2022.06 / Actualidad",
      "stack": "angular, flutter, scss, ds"
    },
    {
      "company": "Appico",
      "text": [
        "UI/UX para la industria automotriz"
      ],
      "what": "frontend engineer",
      "when": "2021.10 / 2022.06",
      "stack": "vue, scrum, agile, scss, ds"
    },
    {
      "company": "Init Services",
      "text": [
        "Aplicaciones híbridas en el ámbito de la salud"
      ],
      "what": "frontend engineer",
      "when": "2019.07 / 2021.10",
      "stack": "angular, vue, pwa, ionic, scss"
    },
    {
      "company": "Zorraquino",
      "text": [
        "Clientes de perfil alto, deadlines semanales, analítica"
      ],
      "what": "fullstack developer",
      "when": "2014.07 / 2019.07",
      "stack": "javascript, scss, html, php, mysql"
    },
    {
      "company": "Ipronet Sistemas",
      "text": [
        "Integración web de cámaras de videovigilancia"
      ],
      "what": "fullstack developer",
      "when": "2011.01 / 2014.07",
      "stack": "mysql, php, codeigniter"
    },
    {
      "company": "Recinfor",
      "text": [
        "Web corporativa, producto propio"
      ],
      "what": "fullstack developer",
      "when": "2009.11 / 2011.01",
      "stack": "javascript, html, scss, mysql, php"
    },
    {
      "company": "Noray Bioinformatics",
      "text": [
        "Software para cultivo celular en hospitales"
      ],
      "what": "fullstack developer",
      "when": "2007.03 / 2007.09",
      "stack": "javascript, html, scss, sqlserver, .net"
    }
  ],
  "intro": {
    "title": "hola, soy Miguel Zubiaga, frontend engineer",
    "text": [
      "Licenciado en informatica, he trabajado en las mejores agencias de diseño/desarrollo.",
      "En Junio del 2022 entré a formar parte de Appinio. Trabajo con un equipo multidisciplinar, siempre tratando de otorgar la mejor experiencia al usuario.",
      "Puedes contactar conmigo por los siguientes canales:"
    ],
    "points": [
      "miguel.zubiaga&commat;gmail.com",
      "linkedin.com/in/mzubiaga",
      "mzubiaga.net"
    ]
  },
  "picture": {
    "title": "finalmente, yo"
  },
  "projects": {
    "title": "algunos proyectos destacados",
    "clients": [
      {
        "title": "Porsche international site",
        "description": "Integración continua del sitio internacional de Porsche con objetivos trimestrales para inversores. Vue, Scrum, Agile, Sistema de Diseño."
      },
      {
        "title": "Axa Health Keeper",
        "description": "Aplicación multi-región y multi-plataforma. El enfoque TDD nos permitió despliegues rápidos y a prueba de fallos tanto en Web, como en Android e IOS. Al estar todo componetizado gracias a Vue, la respuesta ante incidencias y nuevos desarrollos era rápida. Todo el trabajo fue coordinado de forma remota."
      },
      {
        "title": "Kutxabank y Cajasur",
        "description": "Se desarrolló un sistema de diseño con el que mantener una coherencia sobre los diferentes elementos. Una vez creados los componentes base, desplegamos el servicio en una instancia AWS, configurada con los requisitos de seguridad propios del sector bancario. Las actualizaciones semanales traían nuevos contenidos y diseños."
      },
      {
        "title": "Saunier Duval",
        "description": "Necesitaba mejorar su ratio de conversión. Con bootstrap agilizamos la producción y los deadlines. Desarrollamos un buscador de profesionales que respondía ante cualquier input, seleccionandolos según cercanía, valoración y parámetros establecidos por el cliente para promocionar profesionales que cumpliesen ciertos requisitos."
      },
      {
        "title": "Museos Guggenheim Bilbao y Cristobal Balenciaga",
        "description": "Para el museo Guggenheim se aplicó un lavado de cara, dando relevancia con diferentes contrastes a los apartados más importantes. Para Balenciaga se desarrolló desde cero una nueva plataforma con un gestor de contenidos a medida del cliente, que automatizaba secciones relevantes a partir de ciertos eventos y entradas creadas manualmente."
      }
    ]
  },
  "skills": {
    "title": "cómo trabajo",
    "points": [
      "clean code, tdd, pairing, remote,",
      "reactividad, componentes, mobile,",
      "código expresivo y mantenible,",
      "scrum, agile, comunicación,",
      "equipos, atención al detalle en ui/ux,",
      "toma de decisiones, resolutivo,"
    ]
  },
  "stack": {
    "tech": {
      "title": "tech stack",
      "text": [
        "vue, jest, ionic, angular, pwa, php, node, mysql, es6+, javascript, html, css, sass, bem, bootstrap, aws, cordova, capacitor, git, jira"
      ]
    },
    "langs": {
      "title": "hello idiomas",
      "text": [
        "castellano, inglés"
      ]
    }
  },
  "studies": {
    "title": "me formé en",
    "text": [
      "universidad de gales",
      "bachelor of science in computing",
      "centro san luis",
      "desarrollo aplicaciones informáticas",
      "colegio alemán",
      "abitur alemán y selectividad"
    ]
  }
}
