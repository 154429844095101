<template>
  <router-view/>
</template>

<script>
export default {
  methods: {
    updated() {
      window.location.reload(true);
    },
  },
};
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
  @page { margin: 0 }

  body {
    padding: 0;
    margin: 0;
    background-color: #242830;

    @media print {
      width: 210mm;
    }
  }

  p, h1, h2, h3, h4, h5, h6, ul, ul > li, ol, ol > li, label, span {
    font-size: 1em;
    line-height: 1.4em;
    margin: 0px;
    padding: 0px;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  h2 {
    margin-bottom: .25rem
  }

  #app {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 5mm 0;
    color: #2c3e50;
    background-color: #242830;
    width: 100%;
    // min-width: 210mm;

    @media print {
      padding: 0;
      background-color: white;
    }
  }
</style>
