<template>
  <div class="header">
    <div class="header__content">
      <h1 class="header__title">{{ $t('header.title') }}</h1>
      <h2 class="header__subtitle">{{ $t('header.subtitle') }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped lang="scss">
  .header {
    color: #888888;
    display: flex;
    font-size: 16px;
    margin: 1rem auto 2rem auto;

    &__content {
      margin: auto;
    }

    &__title {
      margin: 0;
      padding: 0;
      font-size: 3.00rem;
      line-height: 2rem;
      font-weight: lighter;
      letter-spacing: .5rem;
    }

    &__subtitle {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: lighter;
    }
  }
</style>
