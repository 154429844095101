<template>
  <section class="topbar">
    <div class="topbar__container">
      <Header/>
      <div class="locale-selector">
        <button class="action" @click="print">&#8675;</button>
        <button class="action" @click="changeLocale(switchLocale)">
          {{ switchLocale }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'LocaleSelector',
  components: {
    Header,
  },
  created() {
    this.$i18n.locale = 'es';
  },
  computed: {
    switchLocale() {
      return this.$i18n.locale === 'es' ? 'en' : 'es';
    },
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
    print() {
      window.print();
    },
  },
};
</script>

<style scoped lang="scss">
  .topbar {
    position: sticky;
    width: 100%;
    min-width: 210mm;
    top: 0;
    left: 0;
    z-index: 2;
    background: #ffffff;
    box-shadow: 1px 1px 1px #888888;
    box-sizing: border-box;

    @media only screen and (max-width: 805px) {
      min-width: inherit;
      padding: 0 1rem;
    }

    @media print {
      display: none;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 210mm;
      width: 100%;
      margin: auto;

      .header {
        margin: 0 0;
        transform: scale(.75);
        transform-origin: left;
      }

      .locale-selector {
        display: flex;
        flex-direction: row;
        grid-gap: .5rem;

        button:not(.action) {
          background: none;
          border: none;
          margin: .25rem;
          padding:.5rem;
          color: #888888;
          cursor: pointer;

          &.selected {
            color: #2c3e50;
          }
        }

        .action {
          width: 2rem;
          height: 2rem;
          background: #ffffff;
          border-radius: 0.25rem;
          border: 1px solid #888;
          cursor: pointer;
          transition: all 300ms;

          &:hover {
            background: #242830;
            color: #ffffff;
          }
        }
      }
    }
  }
</style>
