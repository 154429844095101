<template>
  <div class="my my--stack">
    <h2>— {{ $t('stack.tech.title') }}</h2>
    <div class="my--stack__data">
      <p
        v-for="text in stack"
        :key="text"
      >
        {{ $t(`stack.tech.text[${text}]`) }}
      </p>
    </div>
    <Separata smallMargin/>
    <h2>— {{ $t('stack.langs.title') }}</h2>
    <div class="my--languages__data">
      <p
        v-for="text in langs"
        :key="text"
      >
        {{ $t(`stack.langs.text[${text}]`) }}
      </p>
    </div>
    <!--
    <Separata smallMargin/>
    <h2>— {{ $t('stack.other.title') }}</h2>
    <div class="my--languages__data">
      <p
        v-for="text in other"
        :key="text"
      >
        {{ $t(`stack.other.text[${text}]`) }}
      </p>
    </div>
    -->
  </div>
</template>

<script>
import languageJSON from '../locales/es.json';
import Separata from './Separata.vue';

export default {
  name: 'MyStack',
  data() {
    return {
      stack: Object.keys(languageJSON.stack.tech.text),
      langs: Object.keys(languageJSON.stack.langs.text),
      // other: Object.keys(languageJSON.stack.other.text),
    };
  },
  components: {
    Separata,
  },
};
</script>

<style scoped lang="scss">
  .my {

    &--stack {
      font-size: .8rem;

      @media only screen and (max-width: 805px) {
        font-size: 1rem;
      }

      &__data {
        display: grid;
        grid-template-columns: 1fr;
      }
    }

    &--languages {
      font-size: .8rem;

      @media only screen and (max-width: 805px) {
        font-size: 1rem;
      }

      &__data {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
</style>
