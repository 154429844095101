<template>
  <section class="page page--a4">
    <Header/>
    <slot name="content"></slot>
  </section>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'PageA4',
  components: {
    Header,
  },
};
</script>

<style scoped lang="scss">
  .topbar {
    + .page {
      @media only screen and (max-width: 805px) {
        padding-top: 1rem;
        margin-top: 1rem;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
      }
    }
  }

  .page {
    padding: 10mm;
    margin: 5mm auto;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
    background-color: #ffffff;
    border-radius: .25rem;

    @media only screen and (max-width: 805px) {
      padding: 0 1rem;
      margin-bottom: 0;
      margin-top: 0;
      border-radius: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;

      @media only screen and (max-width: 805px) {
        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;
      }
    }

    &--a4 {
      width: 210mm;
      height: 296.6mm;

      @media only screen and (max-width: 805px) {
        width: 95vw;
        height: auto;
      }
    }

    @media print {
      border-radius: 0;
      margin: 0;
    }

    .header {
      @media only screen and (max-width: 805px) {
        display: none
      }
    }
  }
</style>
