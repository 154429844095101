<template>
  <div class="my my--experience">
    <div
      v-for="(job, jobIndex) in experience"
      :key="job"
      class="my--experience__data"
      :style="`font-size: ${16 - jobIndex/2}px`"
    >
      <div class="main">
        <p><strong>{{ $t(`experience[${job}].company`) }}</strong></p>
        <p
          v-for="text in getText(job)"
          :key="text"
        >
          {{ $t(`experience[${job}].text[${text}]`) }}
        </p>
      </div>
      <p class="aside"   :style="`font-size: ${12.8 - jobIndex/2}px`">
        <strong>{{ $t(`experience[${job}].what`) }}</strong><br/>
        {{ $t(`experience[${job}].when`) }}<br/>
        {{ $t(`experience[${job}].stack`) }}
      </p>
    </div>
  </div>
</template>

<script>
import languageJSON from '../locales/es.json';

export default {
  name: 'MyExperience',
  data() {
    return {
      experience: Object.keys(languageJSON.experience),
    };
  },
  methods: {
    getText(index) {
      return Object.keys(languageJSON.experience[index].text);
    },
  },
};
</script>

<style scoped lang="scss">
  .my {

    &--experience {

      &__data {
        margin: 1rem 0;
        display: grid;
        grid-gap: 0 4rem;
        grid-template-columns: 8fr 4fr;

        @media only screen and (max-width: 805px) {
          grid-template-columns: 12fr;
          grid-gap: 0 1rem;
        }

        .aside {
          font-size: .8rem;

          @media only screen and (max-width: 805px) {
            margin-top: .5rem;
            padding-bottom: .5rem;
            border-bottom: 1px dotted #888888;
          }
        }
      }
    }
  }
</style>
