<template>
  <div class="separata" :class="{'separata--small-margin': smallMargin}"></div>
</template>

<script>
export default {
  name: 'Separata',
  props: {
    smallMargin: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
  .separata {
    width: 60%;
    margin: 1.5rem auto 2rem auto;
    border-top: 1px solid #888888;

    &--small-margin {
      width: 40%;
      margin: 1rem auto;
    }

    @media only screen and (max-width: 805px) {
      visibility: hidden;
      margin-bottom: 0;
    }
  }
</style>
