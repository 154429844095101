<template>
  <div class="my my--contact">
    <h2>— {{ $t('clients.title') }}</h2>
    <div class="my--contact__data">
      <div>
        <p
          v-for="cl in companiesLeft"
          :key="cl"
        >
          {{ $t(`clients.companiesLeft[${cl}]`) }}
        </p>
      </div>
      <div>
        <p
          v-for="cr in companiesRight"
          :key="cr"
        >
          {{ $t(`clients.companiesRight[${cr}]`) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import languageJSON from '../locales/es.json';

export default {
  name: 'MyClients',
  data() {
    return {
      companiesLeft: Object.keys(languageJSON.clients.companiesLeft),
      companiesRight: Object.keys(languageJSON.clients.companiesRight),
    };
  },
};
</script>

<style scoped lang="scss">
  .my {

    &--contact {
      font-size: .8rem;

      @media only screen and (max-width: 805px) {
        font-size: 1rem;
      }

      &__data {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
</style>
