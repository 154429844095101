<template>
  <div class="my my--skills">
    <h2>— {{ $t('skills.title') }}</h2>
    <div class="my--skills__data">
      <p
        v-for="text in points"
        :key="text"
        class="point"
      >
        {{ $t(`skills.points[${text}]`) }}
      </p>
    </div>
    <Separata smallMargin/>
  </div>
</template>

<script>
import languageJSON from '../locales/es.json';
import Separata from './Separata.vue';

export default {
  name: 'MySkills',
  data() {
    return {
      points: Object.keys(languageJSON.skills.points),
    };
  },
  components: {
    Separata,
  },
};
</script>

<style scoped lang="scss">
  .my {

    &--skills {
      font-size: .8rem;

      @media only screen and (max-width: 805px) {
        font-size: 1rem;
      }

      &__data {
        display: grid;
        grid-template-columns: 1fr;

        p {
          margin-bottom: .5rem;

          &.point {
            margin-bottom: 0;

            /*
            &:before {
              content: '·';
              display: inline-block;
              margin-right: .5rem;
            }
            */
          }
        }
      }
    }
  }
</style>
