<template>
  <div class="my my--projects">
    <h2>— {{ $t('projects.title') }}</h2>
    <div
      v-for="text in clients"
      :key="text"
      class="my--projects__data"
    >
      <p><strong>{{ $t(`projects.clients[${text}].title`) }}</strong></p>
      <p>{{ $t(`projects.clients[${text}].description`) }}</p>
    </div>
  </div>
</template>

<script>
import languageJSON from '../locales/es.json';

export default {
  name: 'MyProjects',
  data() {
    return {
      clients: Object.keys(languageJSON.projects.clients),
    };
  },
};
</script>

<style scoped lang="scss">
  .my {

    &--projects {

      &__data {
        margin: 1rem 0;
      }
    }
  }
</style>
