<template>
  <div class="my my--studies">
    <h2>— {{ $t('studies.title') }}</h2>
    <div class="my--studies__data">
      <p
        v-for="text in studies"
        :key="text"
      >
        {{ $t(`studies.text[${text}]`) }}
      </p>
    </div>
  </div>
</template>

<script>
import languageJSON from '../locales/es.json';

export default {
  name: 'MyStudies',
  data() {
    return {
      studies: Object.keys(languageJSON.studies.text),
    };
  },
};
</script>

<style scoped lang="scss">
  .my {

    &--studies {
      font-size: .8rem;

      @media only screen and (max-width: 805px) {
        font-size: 1rem;
      }

      &__data {
        display: grid;
        grid-template-columns: 1fr;

        p {

          &:nth-child(2n) {
            margin-bottom: .5rem;
          }
        }
      }
    }
  }
</style>
