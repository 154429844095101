<template>
  <article class="home" ref="pdf">
    <LocaleSelector/>
    <PageA4>
      <template v-slot:content>
        <div class="grid grid--8-4">
          <MyIntro/>
          <MyStack/>
        </div>

        <Separata/>

        <div class="grid grid--8-4">
          <MyCarrer/>
          <MySkills/>
        </div>

        <div class="grid grid--12-0">
          <MyExperience/>
        </div>
      </template>
    </PageA4>
    <PageA4>
      <template v-slot:content>
        <div class="grid grid--8-4">
          <MyProjects/>
          <div>
            <MyClients/>
            <Separata smallMargin/>
            <MyStudies/>
            <Separata smallMargin/>
            <MyPicture/>
          </div>
        </div>
      </template>
    </PageA4>
  </article>
</template>

<script>
import PageA4 from '@/components/PageA4.vue';
import Separata from '@/components/Separata.vue';
import LocaleSelector from '@/components/LocaleSelector.vue';

import MyCarrer from '@/components/MyCarrer.vue';
import MyClients from '@/components/MyClients.vue';
import MyExperience from '@/components/MyExperience.vue';
import MyIntro from '@/components/MyIntro.vue';
import MyPicture from '@/components/MyPicture.vue';
import MyProjects from '@/components/MyProjects.vue';
import MySkills from '@/components/MySkills.vue';
import MyStack from '@/components/MyStack.vue';
import MyStudies from '@/components/MyStudies.vue';

export default {
  name: 'Home',
  components: {
    PageA4,
    Separata,
    LocaleSelector,
    MyCarrer,
    MyClients,
    MyExperience,
    MyIntro,
    MyProjects,
    MyPicture,
    MySkills,
    MyStack,
    MyStudies,
  },
};
</script>

<style scoped lang="scss">
  .grid {
    display: grid;
    grid-gap: 0 4rem;

    &--8-4 {
      grid-template-columns: 8fr 4fr;
    }

    &--12-0 {
      grid-template-columns: 12fr;
    }

    @media only screen and (max-width: 805px) {
      grid-template-columns: 12fr;
      grid-gap: 1rem;
    }
  }
</style>
