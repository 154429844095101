<template>
  <div class="my my--intro">
    <h2>— {{ $t('intro.title') }}</h2>
    <p
      v-for="text in text"
      :key="text"
    >
      {{ $t(`intro.text[${text}]`) }}
    </p>
    <p
      v-for="point in points"
      :key="point"
      class="point"
      v-html="$t(`intro.points[${point}]`)"
    >
    </p>
  </div>
</template>

<script>
import languageJSON from '../locales/es.json';

export default {
  name: 'MyIntro',
  data() {
    return {
      text: Object.keys(languageJSON.intro.text),
      points: Object.keys(languageJSON.intro.points),
    };
  },
};
</script>

<style scoped lang="scss">
  .my {

    &--intro {

      p {
        margin-bottom: .5rem;

        &.point {
          margin-bottom: 0;

          &:before {
            content: '·';
            display: inline-block;
            margin-right: .5rem;
          }
        }
      }
    }
  }
</style>
