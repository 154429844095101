<template>
  <div class="my my--picture">
    <h2>— {{ $t('picture.title') }}</h2>
    <div class="my--picture__data">
      <img src="../assets/me.jpg"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyPicture',
};
</script>

<style scoped lang="scss">
  .my {

    &--picture {
      font-size: .8rem;

      @media only screen and (max-width: 805px) {
        font-size: 1rem;
      }

      &__data {
        position: relative;

        img {
          width: 100%;
          border-radius: .25rem;
          border: none;
          overflow: hidden;
          filter: grayscale(1) brightness(1.25);
          z-index: 1;
        }

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          top: -2px;
          height: 5px;
          z-index: 2;
          border-radius: .25rem;
          background: white;
        }
      }
    }
  }
</style>
