<template>
  <div class="my my--carrer">
    <h2>— {{ $t('carrer.title') }}</h2>
    <p
      v-for="text in carrer"
      :key="text"
    >
      {{ $t(`carrer.text[${text}]`) }}
    </p>
  </div>
</template>

<script>
import languageJSON from '../locales/es.json';

export default {
  name: 'MyCarrer',
  data() {
    return {
      carrer: Object.keys(languageJSON.carrer.text),
    };
  },
};
</script>

<style scoped lang="scss">
  .my {

    &--carrer {

      p {
        margin-bottom: .5rem;
      }
    }
  }
</style>
